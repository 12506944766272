import Link from 'next/link';
import { useContext } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { RedButton } from 'components/common';
import { Main } from 'components/error';
import { ArrowIcon } from 'components/icons';
import { FeedContentContainer } from 'components/newsfeed/style';
import { ServerContext } from 'contexts/ServerContext';
import { TEST_ID } from 'lib/constants';

export const Error404 = () => {
  const serverContext = useContext(ServerContext);

  if (serverContext && serverContext.response) {
    serverContext.response.statusCode = 404;
  }

  return (
    <>
      <Main id="main-content" data-testid={`${TEST_ID.errorPage}`}>
        <ErrorPageWrapper>
          <FeedContentContainer>
            <ContentContainer>
              <TypographySmallBottomMargin variant="body1-strong">Error 404</TypographySmallBottomMargin>
              <TypographySmallBottomMargin variant="page-header">Hoppsan!</TypographySmallBottomMargin>
              <TypographySmallBottomMargin variant="title2">
                Tyvärr kan sidan du letar efter inte hittas.
              </TypographySmallBottomMargin>
              <TypographyLargeBottomMargin variant="title2">
                Men oroa dig inte, kolla in vårt innehåll från länkarna nedan!
              </TypographyLargeBottomMargin>
              <ButtonContainer>
                <Link legacyBehavior href="/nyhetsmorgon" passHref>
                  <RedButton as="a" variant="CTA">
                    Nyhetsmorgon <ArrowIcon />
                  </RedButton>
                </Link>
                <Link legacyBehavior href="/" passHref>
                  <RedButton as="a" variant="CTA">
                    Startsidan <ArrowIcon />
                  </RedButton>
                </Link>
              </ButtonContainer>
            </ContentContainer>
          </FeedContentContainer>
        </ErrorPageWrapper>
      </Main>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  min-height: 75vh;
`;

const ContentContainer = styled.div`
  background-color: ${colors.white[100]};
  padding: ${sizeUnits[24]};
  text-align: left;
`;

const TypographyLargeBottomMargin = styled(Typography)`
  margin-bottom: 48px;
`;

const TypographySmallBottomMargin = styled(Typography)`
  margin-bottom: 24px;
`;
