import { useContext } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { ServerContext } from 'contexts/ServerContext';
import { TEST_ID } from 'lib/constants';
import { isOfflineClient } from 'lib/helpers';

import { RedButton, SkipLinkTarget } from '../common';

export interface CustomLink {
  text: string;
  url: string;
}

interface ErrorProps {
  statusCode?: number;
  customMessage?: string;
  customLink?: CustomLink;
}

export const Main = styled('main')`
  width: 100%;
  min-height: 75vh;
  background: ${colors.gray.dark};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding: 0 ${sizeUnits[16]};
`;

export const Title = styled(Typography)`
  margin: 16px 0;
  text-align: center;
  color: ${colors.black[80]};
`;

export const Details = styled(Typography)`
  margin: 0 0 ${sizeUnits[20]} 0;
  text-align: center;
  padding: 0 ${sizeUnits[20]};
  white-space: pre-wrap;
`;

const getSubTitle = (statusCode?: number) => {
  if (isOfflineClient()) return 'Offline';

  return statusCode === 404 ? 'Sidan existerar inte' : 'Något gick fel';
};

export const CustomError = ({ statusCode, customMessage, customLink }: ErrorProps) => {
  const serverContext = useContext(ServerContext);
  if (serverContext && serverContext.response && statusCode) {
    serverContext.response.statusCode = statusCode;
  }

  const subTitle = getSubTitle(statusCode);
  let message;
  if (customMessage) {
    message = customMessage;
  } else if (isOfflineClient()) {
    message = 'Ingen internet anslutning.\n Kontrollera nätverket och testa att ladda om sidan om en liten stund.';
  } else if (statusCode === 404) {
    message = 'Sidan du försöker nå finns inte.\n Kontrollera att adressen stämmer eller gå tillbaka till startsidan.';
  } else {
    message = 'Testa att ladda om sidan om en liten stund';
  }

  return (
    <>
      <Main id="main-content" data-testid={`${TEST_ID.errorPage}`}>
        <SkipLinkTarget />
        <Wrapper>
          <Title as="h1" variant="title2">
            {isOfflineClient() ? null : (
              <>
                {statusCode ?? 500}
                <br />
              </>
            )}
            {subTitle}
          </Title>
          <Details variant="body1" data-testid="custom-error-message">
            {message}
          </Details>
          <RedButton as="a" variant="CTA" href={customLink ? customLink.url : '/'} data-testid="custom-error-button">
            {customLink ? customLink.text : 'Tillbaka till startsidan'}
          </RedButton>
        </Wrapper>
      </Main>
    </>
  );
};
